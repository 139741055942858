import {
  BackofficeUserRole,
  PermissionMap,
  PermissionOperation,
} from '../../../types';

type PermissionMapByRole = {
  [key in BackofficeUserRole]: PermissionMap;
};

const permissionMapByRole: PermissionMapByRole = {
  systemAdmin: {
    systemAdmin: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    brand: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    owner: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    superAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    masterAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    agent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    player: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    support: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    marketing: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    finance: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    casinoBets: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    casinoGames: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    transaction: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    bet: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    sport: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    competition: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    event: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    market: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    domains: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    theme: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    widget: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    language: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ticket: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    control: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    permission: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    riskBets: {
      read: true,
    },
    exchange: {
      create: true,
      read: true,
      update: true,
    },
    sportline: {
      create: true,
      read: true,
      update: true,
    },
  },
  owner: {
    brand: {
      read: true,
      update: true,
    },
    owner: {
      create: false,
      read: true,
      update: false,
      delete: false,
    },
    superAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    masterAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    agent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    player: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    support: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    marketing: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    finance: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    casinoBets: {
      read: true,
      update: true,
    },
    casinoGames: {
      read: true,
      update: true,
    },
    transaction: {
      create: true,
      read: true,
      update: true,
    },
    bet: {
      read: true,
      update: true,
    },
    sport: {
      read: true,
      update: true,
    },
    competition: {
      read: true,
      update: true,
    },
    event: {
      read: true,
      update: true,
    },
    market: {
      read: true,
      update: true,
    },
    domains: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    theme: {
      read: true,
      update: true,
    },
    widget: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    // language: {
    //   read: true,
    //   update: true,
    // },
    ticket: {
      read: true,
      update: true,
    },
    control: {
      read: true,
      update: true,
    },
    riskBets: {
      read: true,
    },
    exchange: {
      create: true,
      read: true,
      update: true,
    },
    sportline: {
      create: true,
      read: true,
      update: true,
    },
  },
  superAgent: {
    superAgent: {
      read: true,
      update: true,
    },
    masterAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    agent: {
      read: true,
      update: true,
    },
    player: {
      read: true,
      update: true,
    },
    casinoBets: {
      read: true,
    },
    transaction: {
      create: true,
      read: true,
      update: true,
    },
    bet: {
      read: true,
    },
    event: {
      read: true,
    },
    competition: {
      update: true,
    },
    riskBets: {
      read: true,
    },
    exchange: {
      create: true,
      read: true,
      update: true,
    },
  },
  masterAgent: {
    masterAgent: {
      read: true,
      update: true,
    },
    agent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    player: {
      read: true,
      update: true,
    },
    casinoBets: {
      read: true,
    },
    transaction: {
      create: true,
      read: true,
      update: true,
    },
    bet: {
      read: true,
    },
    event: {
      read: true,
    },
    competition: {
      update: true,
    },
    riskBets: {
      read: true,
    },
    exchange: {
      create: true,
      read: true,
      update: true,
    },
  },
  agent: {
    agent: {
      read: true,
      update: true,
    },
    player: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    casinoBets: {
      read: true,
    },
    transaction: {
      create: true,
      read: true,
      update: true,
    },
    bet: {
      read: true,
    },
    event: {
      read: true,
    },
    competition: {
      update: true,
    },
    riskBets: {
      read: true,
    },
    exchange: {
      create: true,
      read: true,
      update: true,
    },
  },
  support: {
    superAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    masterAgent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    agent: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    player: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    casinoBets: {
      read: true,
      update: true,
    },
    casinoGames: {
      read: true,
      update: true,
    },
    transaction: {
      create: true,
      read: true,
      update: true,
    },
    bet: {
      read: true,
      update: true,
    },
    sport: {
      read: true,
      update: true,
    },
    competition: {
      read: true,
      update: true,
    },
    event: {
      read: true,
      update: true,
    },
    market: {
      read: true,
      update: true,
    },
    domains: {
      create: true,
      read: true,
      update: true,
      delete: true,
    },
    ticket: {
      read: true,
      update: true,
    },
    control: {
      read: true,
      update: true,
    },
    riskBets: {
      read: true,
    },
  },
  marketing: {
    brand: {
      read: true,
      update: true,
    },
    domains: {
      read: true,
      update: true,
    },
    theme: {
      read: true,
      update: true,
    },
    widget: {
      read: true,
      update: true,
    },
    // language: {
    //   read: true,
    //   update: true,
    // },
    ticket: {
      read: true,
      update: true,
    },
    control: {
      read: true,
      update: true,
    },
  },
  finance: {
    casinoBets: {
      read: true,
    },
    bet: {
      read: true,
    },
    transaction: {
      read: true,
    },
  },
};

export const operationListForPermissions: PermissionOperation[] = [
  'create',
  'read',
  'update',
  'delete',
];

export default permissionMapByRole;
